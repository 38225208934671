import { TABLA, LPARTE_NOVEDAD } from '@/utils/consts'
import { ReadFileBase64 } from '@/utils/files'

export default {
  async insert (Vue, formData, idparteNovedad, usuarioIdvigilante, idpuestoServicioServicio) {
    const resp = await Vue.$api.call(
      'lparteNovedad.insert',
      {
        values: {
          idparte_novedad: idparteNovedad,
          fnovedad: formData.fnovedad,
          idtnovedad: formData.tnovedad.idtnovedad,
          idfuerza_seguridad: formData.idfuerza_seguridad,
          descripcion: formData.descripcion,
          informe_cliente: formData.informe_cliente,
          estado: LPARTE_NOVEDAD.estados.pendiente,
          latitud: formData.latitud,
          longitud: formData.longitud,
          idpunto: formData.idpunto,
          idpuesto_servicio_servicio: idpuestoServicioServicio,
          idvigilante: usuarioIdvigilante,
        },
      }
    )
    const lparteNovedad = resp.data.result.dataset[0]
    let apiCalls = await Vue.$online.dato.batchCallInsertDeFormData(lparteNovedad.idlparte_novedad, formData.datosNovedad)
    for (const file of formData.ficheros) {
      apiCalls.push({
        name: 'ficheroInsert',
        method: 'fichero.insert',
        params: {
          values: {
            data: await ReadFileBase64(file),
            nombre_fichero: file.name,
            idtabla: TABLA.lparte_novedad.idtabla,
            id: lparteNovedad.idlparte_novedad,
            idtfichero: formData.idtfichero,
            observaciones: null,
          },
        },
      })
    }
    await Vue.$api.batchCall(apiCalls)
    return lparteNovedad
  },
}

<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <lparte-novedad-form
          :geolocalizacion="geolocalizacion"
          :punto-obligatorio="puestoServicio && puestoServicio.novedad_punto_obligatorio"
          :idpuesto-servicio="puestoServicio && puestoServicio.idpuesto_servicio"
          :idpuesto-servicio-servicio="presenciaIdpuestoServicioServicio"
          :es-tecnico="!!usuarioIdtecnico"
          :es-comercial="!!usuarioIdcomercial"
          :es-vigilante="!!usuarioIdvigilante"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import LparteNovedadForm from '../components/LparteNovedadForm'
import Data from './LparteNovedadAddData'
import { captureGeolocationCheck, continuarConGeolocalizacion } from '@/utils/maps'
import { get } from 'vuex-pathify'

export default {
  components: {
    LparteNovedadForm
  },
  mixins: [formPageMixin],
  props: {
    viewRoute: {
      type: String,
      default: '',
    }
  },
  data () {
    return {
      geolocalizacion: {},
      puestoServicio: {},
      idparteNovedad: null,
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    presenciaIdpuestoServicioServicio: get('presencia/idpuestoServicioServicio'),
    presenciaIdpuestoServicio: get('presencia/idpuestoServicio'),
    presenciaIdempresa: get('presencia/idempresa'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    usuarioCapturarGeolocalizacion: get('usuario/capturarGeolocalizacion'),
  },
  async created () {
    // Sólo se permiten crear novedades en el servicio donde se está logeada la presencia
    // este control es preventivo, en teoría no debería nunca poder añadir novedad si no tiene el "presenciaIdpuestoServicioServicio"
    if (!!this.presenciaIdpuestoServicioServicio) {
      const puestoServicio = await this.$online.puestoServicio.select(this.presenciaIdpuestoServicio)
      this.$set(this, 'puestoServicio', puestoServicio)
      await this.initStore()
      await this.loadPage()
    } else {
      this.$alert.showSnackbarError('Debes registrar presencia primero')
    }
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir novedad'
      this.geolocalizacion = await captureGeolocationCheck(this, this.usuarioCapturarGeolocalizacion)
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      if (continuarConGeolocalizacion(this, this.usuarioCapturarGeolocalizacion, this.geolocalizacion)) {
        const inserted = await Data.insert(this, this.formData, this.routeParams.idparte_novedad, this.usuarioIdvigilante, this.presenciaIdpuestoServicioServicio)
        this.$appRouter.replace({
          name: this.viewRoute,
          params: {
            idlparte_novedad: inserted.idlparte_novedad,
          },
        })
      }
    },
  },
}
</script>
